.login {
    width: 100%;
    background: url("../../../../assets/images/dark-material-bg.jpg") no-repeat;
    background-size: cover;
    
    .login-form {
        width: 384px;
        max-width: 384px;
        padding: 32px;
        text-align: center;
    
        .card {
            background-color: white;
            color: black;
        }
        .submit-button {
            background-color: #039be5;
            border-color: transparent;
            background-image: none;
            width: 220px;
            margin: 16px auto;
            padding: 5px 0px;
            :hover,
            :focus,
            :visited {
                background-image: none;
                box-shadow: none;
                outline: none;
            }
        }
        input {
            box-shadow: none;
            outline: none;
            padding: 1.5em 1em;
        }
        .form-fields {
            text-align: left;
        }
        .header {
            color: rgba(0, 0, 0, 0.87);
            letter-spacing: 0.1px;
            font-size: 20px;
            line-height: 28px;
            margin: 16px 0px 32px 0px;
        }
        .logo {
            width: 128px;
            margin: 32px auto;

            img {
                max-width: 100%;
                height: auto;
                vertical-align: top;
            }
        }
    }
}