@import "../../../../assets/sass/theme.scss";

$lighter-6: rgba(255,255,255,0.5);
$lighter-7: rgba(255,255,255,0.55);
$lighter-8: rgba(255,255,255,0.60);

.app-homepage {
    &-main {
        padding: 85px 100px 100px;
        max-width: 1300px;
        min-width: 880px;

        &-actions {
            display: flex;
            justify-content: space-between;
            align-items: end;
            margin-bottom: 20px;
            gap: 20px;

            .searchbar {
                font-size: 14px;
                height: 32px;
                width: 300px;
                padding-left: 10px;
            }
        }
    }

    &-lists-container {
        display: flex;
        overflow: hidden;
        gap: 20px;
    }

    &-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
    }

    &-open-cloud-project {
        display: flex;
        align-items: center;
        margin: 10px 0;
        background-color: $lighter-1;
        &:hover {
            color: #fff;
            background-color: $lighter-3;
            text-decoration: none;
        }
        .icon {
            margin: 10px;
            font-size: 2.5em;
        }
        .title {
            font-size: 1.2em;
            margin: 10px;
        }
    }

    // TODO: try to move this to `CondensedList`
    .condensed-list {
        &-body {
            overflow: hidden;
        }

        &-items {
            overflow: auto;
        }
    }
}

.homepage-modal {
    z-index: 10 !important;
    .modal-container {
        height: 90vh !important;
        width: calc(100% - 32px) !important;
        max-width: calc(100% - 32px) !important;
        display: flex;
        padding: 0 !important;

        .modal-content {
            flex: 1;
            display: flex;
            .header {
                display: flex;
                .title {
                    margin-left: 1em;
                    flex: 1;
                }

                .close-button {
                    color: white;
                }
            }
            .body {
                flex: 1;
                overflow-y: hidden;
                display: flex;
                .modal-left {
                    flex: 1;
                    display: flex;
                    ul {
                        display: flex;
                        flex-direction: row;
                        align-items: stretch;
                        justify-content: space-around;
                        margin: auto;
                        padding: 0;
                        li {
                            list-style-type: none;
                            text-align: center;
                            min-width: 24%;
                            border-radius: 2px;
                            a {
                                display: inline-block;
                                .title {
                                    max-width: 12em;
                                    margin: auto;
                                    font-size: 1.3em;
                                    font-weight: bold;
                                    color: white;
                                    text-align: center;
                                    margin-bottom: 1em;
                                }
                                &.active,
                                &:hover {
                                    color: #fff;
                                    background-color: $lighter-2;
                                    text-decoration: none;
                                    flex-wrap: wrap;
                                }
                            }
                            .description {
                                font-size: 1em;
                                color: $lighter-5;
                            }
                        }
                    }
                }
                .modal-right {
                    flex-basis: 20vw;
                    min-width: 250px;
                    max-width: 300px;
                    overflow-y: auto;
                }
            }
        }
    }
}
