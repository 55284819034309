$color-yellow: #ffbb00;
$color-green: #7cbb00;
$color-blue: #00a1f1;
$color-red: #f65314;

$lighter-1: rgba(255,255,255,0.05);
$lighter-2: rgba(255,255,255,0.10);
$lighter-3: rgba(255,255,255,0.15);
$lighter-4: rgba(255,255,255,0.20);
$lighter-5: rgba(255,255,255,0.35);

$darker-1: rgba(0,0,0,0.05);
$darker-2: rgba(0,0,0,0.10);
$darker-3: rgba(0,0,0,0.15);
$darker-4: rgba(0,0,0,0.20);
$darker-5: rgba(0,0,0,0.25);
$darker-6: rgba(0,0,0,0.5);
$darker-7: rgba(0,0,0,0.35);
$darker-8: rgba(0,0,0,0.40);
$darker-9: rgba(0,0,0,0.45);
$darker-10: rgba(0,0,0,0.50);
$darker-11: rgba(0,0,0,0.55);
$darker-12: rgba(0,0,0,0.60);
$darker-13: rgba(0,0,0,0.65);
$darker-14: rgba(0,0,0,0.70);
$darker-15: rgba(0,0,0,0.75);
